<template>
  <div
    class="input"
    :class="{
      'input--error': errorMessage,
    }"
    v-if="visible"
  >
    <span class="input__title">
      <span class="required__symbol" v-if="required">*</span>
      <span> {{ title }}</span>
    </span>
    <div class="input__container">
      <Multiselect
        :track-by="trackBy"
        :label="labelBy"
        v-model="selectValue"
        :options="options"
        :placeholder="placeholder"
        :close-on-select="!multiple"
        selectLabel="Выбрать ↵"
        deselectLabel="Удалить ↵"
        selectedLabel="Выбрано"
        :searchable="searchable"
        :allow-empty="allowEmpty"
        :multiple="multiple"
        @select="$emit('input')"
      >
        <span slot="noOptions">Список пуст</span>
        <span slot="noResult">Ничего не найдено</span>
      </Multiselect>
      <span class="form__error" role="alert" v-if="errorMessage">{{ errorMessage }}</span>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "SelectComponent",
  props: {
    title: String,
    trackBy: {
      type: String,
      default: "id",
    },
    labelBy: {
      type: String,
      default: "name",
    },
    placeholder: {
      type: String,
      default: "Выберите из списка",
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    allowEmpty: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
    visible: {
      type: Boolean,
      default: true,
    },
    value: [Number, String, Object, Array],
    options: Array,
    errorMessage: String,
  },
  data() {
    return {
      selectValue: this.value,
    };
  },
  watch: {
    selectValue(e) {
      this.$emit("input", e);
    },
    value(e) {
      this.selectValue = e;
    },
  },
  components: {
    Multiselect,
  },
};
</script>

<style lang="stylus">
@import '~vue-multiselect/dist/vue-multiselect.min.css'
</style>
