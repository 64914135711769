<template>
  <div
    class="input date-select"
    :class="{
      'input--default': notEmpty || focused,
      'input--error': errorMessage,
    }"
    v-if="visible"
    @click="focus"
  >
    <span class="input__title">
      <span class="required__symbol" v-if="required">*</span>
      <span> {{ title }}</span>
    </span>
    <div class="input__container">
      <date-picker
        v-model="selectedDate"
        :disabled-date="disabledDate"
        format='DD.MM.YYYY'
        :type="mode"
        :range="range"
        lang="ru"
        @input="$emit('input', $event)"
        @close="blur"
        @focus="focus"
      />
      <span class="input__error" role="alert" v-if="errorMessage">{{ errorMessage }}</span>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import CloseIcon from "@/components/svg/CloseIcon.vue"
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';

export default {
  name: "DateComponent",
  props: {
    title: String,
    placeholder: {
      type: String,
      default: "гггг-мм-дд",
    },
    required: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: true,
    },
    mode: {
      type: String,
      default: "date",
    },
    range: Boolean,
    disabledDate: {
      type: Function,
      default: (date) => date >= new Date()
    },
    value: [Number, String, Object, Array, Date],
    errorMessage: String,
  },
  data() {
    return {
      focused: false,
      selectedDate: undefined,
    };
  },
  mounted() {
    if (this.value) {
      this.selectedDate = new Date(this.value);
    }
  },
  watch: {
    value() {
      this.selectedDate = this.value;
    },
  },
  computed: {
    notEmpty() {
      if (typeof this.selectedDate === "string") {
        return this.selectedDate && this.selectedDate.length;
      }
      return !!this.selectedDate;
    },
  },
  methods: {
    focus() {
      if (!this.focused) {
        this.focused = true;
      }
      this.$emit("focus");
    },
    blur() {
      if (this.focused) {
        this.focused = false;
      }
      this.$emit("blur");
    },
  },
  components: {
    DatePicker,
    CloseIcon,
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style lang="stylus">
.date-select {
  display flex
  flex-direction: column
  gap: 5px


  .mx-datepicker,
  .mx-input-wrapper,
  input {
    width: 100% !important
    height: 100% !important
    border none !important
  }

  .mx-input {
    font-size: 16px !important;
    line-height: 26px !important;
  }

  &__title {
    font-size: 0.75em;
    line-height: 13px;
    color: var(--body-color);
    opacity: 0.5;
    white-space: pre-line;
  }
}
</style>
