<template>
  <div
    class="check__container"
    :class="{
      'check--error': errorMessage,
      'no-print': noPrint,
    }"
    v-if="visible"
  >
    <div class="check__container">
      <div class="check__list no-print">
        <label
          :class="{
            'check--disabled': disabled,
            'check--checked': inputValue,
          }"
          class="check"
        >
          <span class="check__state"></span>
          <input
            type="checkbox"
            v-model="inputValue"
            class="check__input"
            :disabled="disabled"
          />
          <slot>
            <span class="check__input-title">
              <span class="required__symbol" v-if="required">*</span> {{ title }}
            </span>
          </slot>
        </label>
      </div>
      <span class="form__error" role="alert" v-if="errorMessage">{{ errorMessage }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckboxComponent",
  props: {
    noPrint: Boolean,
    title: String,
    placeholder: {
      type: String,
      default: "",
    },
    options: Array,
    required: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: true,
    },
    value: [Number, String, Object, Boolean],
    errorMessage: String,
  },
  data() {
    return {
      inputValue: this.value,
    };
  },
  watch: {
    inputValue(e) {
      this.$emit("input", e);
    },
  },
};
</script>

<style lang="stylus">
.check {
  display flex
  position relative
  cursor pointer
  align-items: center;
  text-align: left;
  user-select none
  font-size: 1em;
  line-height: 16px;
  transition 0.2s
  margin-bottom 12px
  padding 3px
  --check-offset: 2px
  --check-width: 40px
  --check-height: 22px

  > *:not(:last-child) {
    margin-right 15px
  }

  &:hover {
    color var(--main_color)
    filter brightness(1.1)

    .check__state {
      border-color var(--main_color)
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__title {
    margin-bottom 24px
  }

  input {
    absolute left top
    visibility hidden
  }

  a {
    color var(--main_color)
  }

  .icon {
    display flex
  }

  &--error {
    color var(--color_red) !important

    .check__state {
      border-color var(--color_red) !important
    }
  }

  &--disabled {
    color var(--color_gray)

    .check__state {
      border: 1px solid var(--color_gray);
    }

    &.check--checked {
      .check__state {
        &:after {
          background: var(--color_dark);
        }
      }
    }
  }

  &--checked {
    &:not(&.check--disabled) {
      & .check__state {
        border none
        background var(--main_gradient)
        box-shadow: 0 4px 10px rgba(255, 145, 91, 0.5);

        &::before {
          left calc(var(--check-width) - var(--check-height) + var(--check-offset))
        }
      }
    }
  }

  &__container {
    &:first-child {
      margin-top 5px
    }
  }

  &__state {
    position relative
    width var(--check-width)
    height var(--check-height)
    background: var(--color_gray);
    box-sizing: border-box;
    border-radius: 12px;
    display flex
    align-items center
    justify-content center
    flex-shrink 0
    transition 0.2s

    &::before {
      content ""
      display block
      position absolute
      top: var(--check-offset)
      left var(--check-offset)
      background-color var(--color_white)
      width calc(var(--check-height) - var(--check-offset) * 2)
      height calc(var(--check-height) - var(--check-offset) * 2)
      border-radius 50%
      transition 0.2s
    }
  }
}
</style>