<template>
  <div v-click-outside="blur" class="dadata">
    <input
      class="input__field"
      type="text"
      v-model="query"
      @input="debouncedInput"
      @focus="focus"
    >
    <ul v-if="suggestions && suggestions.length && focused" class="dadata-suggestions">
      <li
        @click="selectValue(s)"
        class="dadata-suggestions__item"
        v-for="(s, i) in suggestions"
        :key="i"
        v-html="s.valueHighlighted"
      >
      </li>
    </ul>
  </div>
</template>

<script>
import axios from "axios";
import ClickOutside from "vue-click-outside";

export default {
  name: "DadataComponent",
  props: {
    value: String,
  },
  data() {
    return {
      query: this.value || "",
      active: null,
      timeout: null,
      suggestions: [],
      focused: false,
      config: {
        token: this.$store.state.config.dadata_token,
        closeOnSelect: true,
      }
    }
  },
  watch: {
    query() {
      this.$emit("input", this.query)
    }
  },
  created() {
    if (this.value) {
      this.get().then(() => {
        this.findActive();
      })
    }
  },
  methods: {
    selectValue(s) {
      this.query = s.value;
      this.active = s;
      this.$emit("setActive", s)
      this.highlightSuggestions();
      if (this.config.closeOnSelect) {
        this.blur();
      }
    },
    // выделение совпдающих частей адреса
    highlightSuggestions() {
      this.suggestions.forEach(item => {
        let index = item.value ? item.value.toLowerCase().indexOf(this.query.toLowerCase()) : null;
        let queryPart = item.value.slice(index, index + this.query.length)
        item.valueHighlighted = item.value.replace(new RegExp(this.query,'gi'), `<b>${queryPart}</b>`);
      })
    },
    debouncedInput() {
      this.highlightSuggestions();
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.findActive();
        this.get()
      }, 300);
    },
    findActive() {
      let activeSuggestion = this.suggestions.find(item => item.value === this.value)
      this.active = activeSuggestion;
      this.$emit("setActive", activeSuggestion)
    },
    get() {
      return axios.post("https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address", {
        query: this.query,
      }, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": "Token " + this.config.token,
        },
      }).then(({ data }) => {
        this.suggestions = data.suggestions || [];
        this.highlightSuggestions();
      })
    },
    focus() {
      if (!this.focused) {
        this.focused = true;
        if (this.query) {
          this.get();
        }
      }
      this.$emit("focus");
    },
    blur() {
      if (this.focused) {
        this.focused = false;
      }
      this.$emit("blur");
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style lang="stylus">
.dadata {
  position relative
  height 100%
  width 100%

  &-suggestions {
    position absolute
    top 100%
    left 0
    right 0
    max-height 200px
    overflow auto
    background var(--color_white)
    z-index 10
    box-shadow 0 20px 60px rgba(0,0,0,0.15)

    &__item {
      padding 5px 10px
      cursor pointer
      font-size 0.8em

      &:hover {
        background var(--color_gray_divider)
      }
    }
  }
}
</style>