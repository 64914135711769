<template>
  <label
    class="input"
    :class="{
      'input--default': notEmpty || focused,
      'input--error': errorMessage,
    }"
    v-if="visible"
  >
    <span v-if="title" class="input__title">
      <span class="required__symbol" v-if="required">*</span>
      <span> {{ title }}</span>
    </span>
    <span class="input__container">
      <slot>
        <input
          :type="type"
          :placeholder="placeholder"
          class="input__field"
          v-mask="mask"
          :value="value"
          @click="$emit('click')"
          @blur="blur"
          @focus="focus"
          @input="$emit('input', $event.target.value)"
          @keyup.enter="$emit('submit')"
          :autofocus="!!autofocus"
        />
      </slot>
      <span class="form__error" role="alert" v-if="errorMessage">{{ errorMessage }}</span>
      <span class="input__message" role="alert" v-if="message">{{ message }}</span>
    </span>
  </label>
</template>

<script>
import { VueMaskDirective } from "v-mask";
export default {
  name: "InputComponent",
  props: {
    title: String,
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    required: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: true,
    },
    value: [Number, String, Object],
    errorMessage: String,
    message: String,
    mask: String,
    isDouble: {
      type: Boolean,
      default: false,
    },
    autofocus: Boolean,
  },
  data() {
    return {
      focused: false,
    };
  },
  computed: {
    notEmpty() {
      if (typeof this.value === "string") {
        return this.value && this.value.length;
      }
      return this.value !== null && this.value !== undefined;
    },
  },
  methods: {
    focus() {
      if (!this.focused) {
        this.focused = true;
      }
      this.$emit("focus");
    },
    blur() {
      if (this.focused) {
        this.focused = false;
      }
      this.$emit("blur");
    },
  },
  directives: {
    mask: VueMaskDirective,
  },
};
</script>
