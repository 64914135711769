<template>
  <figure :class="`icon check-icon`">
    <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.00903 9C4.50075 9 4.88209 8.83101 5.143 8.46406L9.73909 1.94582C9.91972 1.68991 10 1.42436 10 1.18777C10 0.511803 9.43803 0 8.7155 0C8.2288 0 7.90768 0.168991 7.61164 0.618026L3.98896 6.02575L2.21776 4.11856C1.96688 3.84335 1.66583 3.71298 1.26443 3.71298C0.536879 3.71298 0 4.22479 0 4.90558C0 5.21942 0.0852985 5.46084 0.361264 5.76019L2.93527 8.55579C3.22127 8.86481 3.5725 9 4.00903 9Z" fill="white"/>
    </svg>
  </figure>
</template>

<script>
export default {
  name: "CheckIcon"
}
</script>
