<template>
  <figure :class="`icon close-icon`">
    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M22.6047 6.39688C23.0766 6.86876 23.0766 7.63384 22.6047 8.10572L8.10475 22.6057C7.63286 23.0776 6.86779 23.0776 6.3959 22.6057C5.92402 22.1338 5.92402 21.3688 6.3959 20.8969L20.8959 6.39688C21.3678 5.925 22.1329 5.925 22.6047 6.39688Z" fill="#2E2E2E"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.3959 6.39688C6.86779 5.925 7.63286 5.925 8.10475 6.39688L22.6047 20.8969C23.0766 21.3688 23.0766 22.1338 22.6047 22.6057C22.1329 23.0776 21.3678 23.0776 20.8959 22.6057L6.3959 8.10572C5.92402 7.63384 5.92402 6.86876 6.3959 6.39688Z" fill="#2E2E2E"/>
    </svg>
  </figure>
</template>

<script>
export default {
  name: "CloseIcon"
}
</script>
