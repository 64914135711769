<template>
  <LoadingIndicator class="form-loading" v-if="loadingQuestions"/>
  <form v-else @submit.prevent="formSubmit" class="form" novalidate spellcheck="false">
    <div v-for="(group, i) in questions_data" :key="i" class="form__group">
      <h3 class="title">{{ group.name }}</h3>
      <span class="subtitle subtitle--orange" v-if="group.questions.find(i => i.logicalName === 'Address')">
        {{ bonusMessage }}
      </span>
      <span class="subtitle" v-if="i === 0">
        Поля, отмеченные <span class="required__symbol">*</span> обязательны для заполнения
      </span>
      <div class="form__items">
        <component
          v-for="(question, index) in group.questions.filter(i => i.answer)"
          :key="index"
          :is="getComponent(question)"
          :title="question.name"
          v-model="question.answer.value"
          :type="getType(question)"
          :required="isRequired(question)"
          :readonly="question.isReadOnly"
          :visible="isVisible(question)"
          :multiple="question.isMultiSelect"
          :options="question.fixedAnswers"
          :error-message="question.errorMessage"
          :isDouble="question.questionType === 'Double'"
          :fio="fio"
          @input="updateData(question, $event)"
        >
          <DadataComponent
            v-model.trim="question.answer.value"
            v-if="question.logicalName === 'Address'"
            title="Адрес"
            @setActive="activeAddress = $event"
          />
        </component>
      </div>
    </div>
    <div class="form__group">
      <CheckboxComponent v-model="form.terms">
      <span>
        Я согласен с <router-link :to="{ name: 'terms' }" target="_blank">пользовательским соглашением</router-link>
        и с <router-link :to="{ name: 'personaldata' }" target="_blank">обработкой персональных данных</router-link>
      </span>
      </CheckboxComponent>
      <CheckboxComponent v-if="needSubscription" v-model="form.subscription">
        <span>Я согласен получать рекламные рассылки об акциях и скидках</span>
      </CheckboxComponent>
    </div>
    <div class="form__error" v-if="error">{{ error }}</div>
    <div class="form__actions">
      <button @click.prevent="prevStep" type="button" class="btn-main">
        Назад
      </button>
      <button class="btn-main" type="submit" :disabled="loading || !form.terms">
        <LoadingIndicator v-if="loading"/>
        <template v-else>Отправить</template>
      </button>
    </div>
  </form>
</template>

<script>
import LoadingIndicator from "@/components/LoadingIndicator";
import InputComponent from "@/components/form/InputComponent";
import RadioComponent from "@/components/form/RadioComponent";
import SelectComponent from "@/components/form/SelectComponent";
import CheckboxComponent from "@/components/form/CheckboxComponent";
import DateComponent from "@/components/form/DateComponent";
import DadataComponent from "@/components/DadataComponent";
import moment from "moment";
import axios from "axios";
import { UTM_ID } from "@/constants/constants";
// import axios from "axios";

export default {
  name: "QuestionsComponent",
  props: {
    codes: Object,
    isBonusCard: Boolean,
  },
  data() {
    return {
      errorMessage: "Обязательное поле",
      bonusMessage: "Получите дополнительные бонусы за заполнение данных",
      loading: false,
      loadingQuestions: true,
      error: null,
      subConfirm: false,
      fio: {
        first: "",
        middle: "",
        last: "",
      },
      form: {
        terms: true,
        subscription: true,
      },
      answers: [],
      activeAddress: null,
      needSubscription: true,
      aifi_question: {},
    }
  },
  async beforeCreate() {
    await this.$store.dispatch('USER_SUBSCRIPTIONS', {
      token: localStorage.getItem("auth-token")
    }).then(({ data }) => {
      if (data.result && data.result.state === "Success") {
        this.needSubscription = false;
        if (data && data.data && data.data.length) {
          this.needSubscription = data.data.some(item => !item.selected);
        } else {
          this.needSubscription = true;
        }
      }
    })
    await this.$store.dispatch('GET_QUESTIONS', {
      token: localStorage.getItem("auth-token")
    })
      .then(() => {
        this.loadingQuestions = false;
        if (this.questions_data && this.questions_data.length) {
          this.questions_data.forEach(group => {
            if (group.questions && group.questions.length) {
              this.answers.push(...group.questions.map(question => {
                // answer.value пусто, но есть ответ в fixedAnswerIds
                // то беру оттуда и сохраняю в answer.value
                if (
                    question.answer &&
                    !question.answer.value &&
                    question.answer.fixedAnswerIds &&
                    question.answer.fixedAnswerIds.length === 1
                ) {
                  question.answer.value = question.answer.fixedAnswerIds[0]
                }
                return question.answer
              }))
            }
          })
        }
      })
      .catch(() => {
        this.loadingQuestions = false;
      })
  },
  created() {
    this.setAifi()
  },
  beforeDestroy() {
    this.$store.state.questions_data = []
  },
  watch: {
    'form.terms'() {
      this.error = this.form.terms ? null : "Нужно принять пользовательское соглашение для продолжения регистрации"
    }
  },
  computed: {
    questions_data() {
      return this.$store.state.questions_data
    },
  },
  methods: {
    countErrors() {
      let errors = 0;
      this.questions_data.forEach(d => {
        d.questions.forEach(answer => {
          if (answer.errorMessage) errors++
        })
      })
      return errors;
    },
    isRequired(question) {
      return this.$store.state.config.required_questions.includes(question.logicalName) || question.isRequired;
    },
    isVisible(question) {
      return this.$store.state.config.visible_questions.includes(question.logicalName) || question.isVisibleInRegistration;
    },
    // инициализация скрытого вопроса для адреса
    setAifi() {
      this.aifi_question = JSON.parse(JSON.stringify(this.$store.state.config.aifi_question))
    },
    prevStep() {
      if (this.isBonusCard) {
        this.$emit("changeStep", this.codes.CARD_SET_CODE)
      } else {
        if (this.$store.state.config.email) {
          this.$emit("changeStep", this.codes.EMAIL_CONFIRM_CODE)
        } else if (this.$store.state.config.card) {
          this.$emit("changeStep", this.codes.CARD_BINDING_CODE)
        } else {
          this.$emit("changeStep", this.codes.PASSWORD_CHANGE_CODE)
        }
      }
    },
    // обновление данных после ввода
    updateData(question) {
      let answer = question.answer
      this.answers.splice(this.answers.findIndex(a => answer && a.questionId === answer.questionId), 1, answer)
      if (question.logicalName === "FirstName") {
        this.fio.first = question.answer.value
      } else if (question.logicalName === "LastName") {
        this.fio.last = question.answer.value
      } else if (question.logicalName === "PatronymicName") {
        this.fio.middle = question.answer.value
      } else if (question.logicalName === "Address") {
        this.setAifi()
      }
      question.errorMessage = null
    },
    getComponent(question) {
      switch (question.questionType) {
        case "String":
        case "Double":
          return InputComponent;
        case "Date":
          return DateComponent;
        case "Select": {
          if (question.logicalName === "Sex") {
            return RadioComponent
          }
          return SelectComponent
        }
        case "Int":
          return InputComponent;
        case "Boolean":
          return CheckboxComponent;
        default:
          return InputComponent
      }
    },
    getType(question) {
      switch (question.questionType) {
        case "String":
        case "Double":
          return "text";
        case "Date":
          return "date";
        case "Int":
          return "number";
        case "Select":
          return "radio";
        case "Boolean":
          return "checkbox";
        default:
          return "text"
      }
    },
    formSubmit() {
      if (!this.loading) {
        if (this.form.terms) {
          this.loading = true
          this.resetValidation()
          this.checkValidation()
          if (!this.countErrors()) {
            if (this.form.subscription) {
              // подписка на рассылку
              this.$store.dispatch("SUBSCRIPTIONS_CONFIRM", {
                token: localStorage.getItem("auth-token")
              })
            }
            // пользовательское соглашение и персональные данные
            this.$store.dispatch("ACCEPT_TENDER_OFFER", {
              token: localStorage.getItem("auth-token")
            }).then(() => {
              // если это select то меняем value
              let answersChanged = JSON.parse(JSON.stringify(this.answers))
              answersChanged.forEach(answer => {
                let question = null;
                if (this.questions_data) {
                  this.questions_data.forEach(q => {
                    q.questions.forEach(i => {
                      if (i.id === answer.questionId) {
                        question = i
                        return null;
                      }
                    })
                  })
                }

                if (question) {
                  if (question.id === UTM_ID && !!this.$route?.query?.UTM) {
                    answer.value = this.$route.query.UTM;
                  }
                  if (question && question.questionType === "Date") {
                    if (answer.value) {
                      // костыли, потому что отправляю один формат, апи принимает другой, а возвращает третий
                      let value = answer.value
                      answer.value = moment(value, "DD.MM.YYYY").format("YYYY-MM-DD")
                      if (isNaN(Date.parse(answer.value))) {
                        answer.value = moment(value).format("YYYY-MM-DD")
                      }
                    } else {
                      answer.value = null
                    }
                  }

                  // если адрес введен правильно
                  // if (question && question.logicalName === "Address" && !question.errorMessage) {
                  //   if (this.isRequired(question) || question.answer.value) {
                  //     this.aifi_question.value = "true"
                  //   }
                  // }
                  // если это селект с множественным выбором, то отправляем массив id выбранных значений в fixedAnswerIds
                  if (question && question.isMultiSelect) {
                    answer.fixedAnswerIds = answer.value.map(an => an.id)
                    answer.value = answer.value.map(an => an.id)
                  }
                  // если это селект с единственным выбором, то отправляем id выбранного значения в fixedAnswerIds
                  else if (answer && answer.value && typeof answer.value === "object") {
                    answer.value = answer.value.id
                    answer.fixedAnswerIds = [answer.value]
                  }
                  // если есть выбор из нескольких значений в fixedAnswers, то отправляем выбранный элемент в массиве fixedAnswerIds
                  else if (answer && answer.value && answer.fixedAnswerIds !== null) {
                    answer.fixedAnswerIds = [answer.value]
                  }
                }
              })
              answersChanged = this.unique(answersChanged)
              if (this.aifi_question && this.aifi_question.value === "true") {
                answersChanged.push(this.aifi_question)
              }
              this.$store.dispatch("POST_ANSWERS", {
                data: answersChanged,
                token: localStorage.getItem("auth-token")
              })
                .then(({ data }) => {
                  if (data.data && data.data.errors && data.data.errors.length) {
                    data.data.errors.forEach(err => {
                      let index = -1;
                      this.questions_data.forEach(q => {
                        index = q.questions.findIndex(q => q.id == err.idQuestion)
                        if (index !== -1) {
                          q.questions[index].errorMessage = err.errors[0]
                        }
                      })
                    })
                  } else if (data.result && data.result.state === "Success") {
                    this.tryFinishRegistration()
                  } else if (data.result && data.result.state === "Error") {
                    this.error = data.result.message;
                  }
                  this.loading = false
                })
                .catch((e) => {
                  this.loading = false
                });
            })
          } else {
            this.loading = false;
          }
        }
      }
    },
    tryFinishRegistration() {
      this.$store.dispatch("TRY_FINISH_REGISTRATION", {
        token: localStorage.getItem("auth-token")
      }).then(() => {
        if (this.isBonusCard) {
          this.$store.dispatch('GET_EMAIL', {
            token: localStorage.getItem("auth-token")
          })
              .then(({ data }) => {
                if (data.data && data.data.currentEmail && data.result.state === "Success") {
                  this.$emit("changeStep", this.$store.state.codes.bonus_cards.FINISH_CODE)
                } else {
                  this.$emit("changeStep", this.$store.state.codes.bonus_cards.EMAIL_CHANGE)
                }
              })
              .catch(() => {
                this.$emit("changeStep", this.$store.state.codes.bonus_cards.EMAIL_CHANGE)
              })
        } else {
          this.$emit('changeStep', this.codes.FINISH_CODE)
          const urlParams = new URLSearchParams(window.location.search);
          urlParams.delete("card")
          history.replaceState(null, "", location.pathname + urlParams);
        }
      })
    },
    checkValidation() {
      this.questions_data.forEach(d => {
        d.questions.forEach(question => {
          if (!question.answer.value) {
            if (this.isRequired(question)) {
              question.errorMessage = this.errorMessage;
            }
          }
          // если это адрес, то проверяю на корректный ввод
          else if (question.logicalName === "Address") {
            if (this.activeAddress) {
              if (!this.activeAddress.data?.street) {
                question.errorMessage = "Добавьте улицу"
              } else if (!this.activeAddress.data?.house) {
                question.errorMessage = "Добавьте дом"
              } else {
                // если адрес введен правильно
                this.aifi_question.value = "true"
              }
            } else {
              question.errorMessage = "Выберите адрес из выпадающего списка"
            }
          }
        })
      })
    },
    resetValidation() {
      if (
        this.questions_data &&
        this.questions_data.length
      ) {
        this.questions_data.forEach(d => {
          d.questions.forEach(item => {
            item["errorMessage"] = null
          })
        })
      }
    },
    unique(arr) {
      let result = [];
      for (let item of arr) {
        if (!result.find(i => item && i.questionId === item.questionId)) {
          result.push(item);
        }
      }
      return result;
    },
  },
  components: {
    InputComponent,
    DadataComponent,
    CheckboxComponent,
    LoadingIndicator,
  }
}
</script>
